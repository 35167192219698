import React from "react";
import { Link } from "gatsby";
import Img_person from "../images/icons/person.svg";
import Img_lock from "../images/icons/lock.svg";

export default ({
  handleSubmit,
  handleSubmitEmail,
  handleUpdate,
  rememberMe,
  show_password_field,
  props,
}) => {
  return (
    <>
      <div className="login">
        <h1 className="text--center">{props.langData.title_login}</h1>

        <p className="p--sm text--center mart--xxl marb--xxl subtitle">
          {props.langData.subtitle}
        </p>
        {show_password_field == false ? (
          <>
            <form
              className="mart--xl"
              method="post"
              onSubmit={(event) => {
                handleSubmitEmail(event);
              }}>
              <div className="form__row">
                <div className="input__group">
                  <img src={Img_person} alt="" />
                  <input
                    type="email"
                    name="email"
                    placeholder={props.langData.placeholder_email}
                    className="input"
                    onChange={handleUpdate}
                  />
                </div>
                <span
                  style={{ display: "none" }}
                  className="error_email action_status status_error"></span>
                <span
                  style={{ display: "none" }}
                  className="link_regiser action_status">
                  {props.langData.sugest_register_link}
                  <Link className="link" to={props.urlSignUp}>
                    {props.langData.register_link}
                  </Link>
                  {props.langData.sugest_register_link_2}
                </span>
              </div>

              <div className="form__row">
                <button
                  id="btn_first_login"
                  className="button button--cta button--full"
                  type="submit">
                  {props.langData.btn_next_label}
                </button>
              </div>

              <p className="p--sm mart--xxl text--center">
                {props.langData.title_register}
                <Link to={props.urlSignUp} className="link">
                  {props.langData.join_label}
                </Link>
              </p>
            </form>
          </>
        ) : (
          <>
            <form
              className="mart--xl"
              method="post"
              onSubmit={(event) => {
                handleSubmit(event);
              }}>
              <div className="form__row">
                <div className="input__group">
                  <img src={Img_person} alt="" />
                  <input
                    type="email"
                    name="email"
                    placeholder={props.langData.placeholder_email}
                    className="input"
                    onChange={handleUpdate}
                  />
                </div>

                <span
                  style={{ display: "none" }}
                  className="error_email action_status status_error"></span>
                <span
                  style={{ display: "none" }}
                  className="link_regiser action_status">
                  {props.langData.sugest_register_link}
                  <Link className="link" to={props.urlSignUp}>
                    {props.langData.register_link}
                  </Link>
                  {props.langData.sugest_register_link_2}
                </span>
              </div>

              <div className="form__row">
                <div className="input__group">
                  <img src={Img_lock} alt="" />
                  <input
                    type="password"
                    name="password"
                    placeholder={props.langData.placeholder_password}
                    className="input"
                    onChange={handleUpdate}
                  />
                </div>
                <span
                  style={{ display: "none" }}
                  className="error_password action_status status_error"></span>
              </div>

              <div className="form__row">
                <button
                  id="btn__login"
                  className="button button--cta button--full"
                  type="submit">
                  {props.langData.btn_label}
                </button>
              </div>

              <p className="p--sm mart--xxl text--center">
                <Link to={props.urlForgetPassword} className="link">
                  {props.langData.forgot_password_label}
                </Link>
              </p>
            </form>
          </>
        )}
      </div>
    </>
  );
};
