import React from "react";
import FormLogin from "../components/FormLogin";
import $ from "jquery";
import { navigate } from "gatsby";
import { checkUserEmail, handleLogin } from "./Auth";
import { saveState, loadState } from "../state/utils/localStorage";
import { getRewardsProfile } from "./Guest";
import { withAuthenticationRequired } from "@auth0/auth0-react";

const isAuth0Enabled = process.env.GATSBY_ENABLE_AUTH0 === "true";
const languageSession = loadState("AYANA_LANG_SESSION");

class Login extends React.Component {
  dateNow = new Date().getTime();
  state = {
    email: "",
    password: "",
    setRememberMe: false,
    show_password_field: false,
  };

  rememberMe() {
    this.setState({
      setRememberMe: !this.state.setRememberMe,
    });
  }

  handleUpdate(event) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  handleSubmitEmail(event) {
    try {
      event.preventDefault();
      $(".action_status").css("display", "none");
      $("#btn_first_login").text("...");
      document.getElementById("btn_first_login").disabled = true;

      if (this.state.email == "") {
        this.showErrorEmail("Email address required");
        $("#btn_first_login").text(this.props.langData.btn_next_label);
        document.getElementById("btn_first_login").disabled = false;
      } else {
        (async () => {
          const { data, message, status_code, success } = await checkUserEmail(
            this.state
          );
          if (success == true && status_code == 200110) {
            return this.showPaswordField(true);
          } else if (success == true && status_code == 404100) {
            this.showErrorEmail(message);
            $("#btn_first_login").text(this.props.langData.btn_next_label);
            document.getElementById("btn_first_login").disabled = false;
          } else if (success == true && status_code == 200111) {
            // redirect to activation url
            saveState("setupTime", this.dateNow);
            $("#btn_first_login").text(this.props.langData.btn_next_label);
            navigate(this.props.activationUrl + "?email=" + this.state.email);
          } else if (success == true || success == false) {
            // another error
            this.showErrorEmail(message);
            $("#btn_first_login").text(this.props.langData.btn_next_label);
            document.getElementById("btn_first_login").disabled = false;
          } else {
            // no connection
            this.showErrorEmail(this.props.langData.login_error_no_connection);
            $("#btn_first_login").text(this.props.langData.btn_next_label);
            document.getElementById("btn_first_login").disabled = false;
          }
        })();
      }
    } catch (e) {
      console.log(e);
    }
  }

  handleSubmit(event) {
    try {
      event.preventDefault();
      $(".action_status").css("display", "none");
      $("#btn__login").text("...");
      document.getElementById("btn__login").disabled = true;

      if (this.state.email == "" && this.state.password == "") {
        $(".error_email")
          .css("display", "block")
          .text("Email address required");
        $(".error_password").css("display", "block").text("Password required");
        document.getElementById("btn__login").disabled = false;
        $("#btn__login").text(this.props.langData.btn_label);
      } else if (!this.state.email) {
        $(".error_email")
          .css("display", "block")
          .text("Email address required");
        document.getElementById("btn__login").disabled = false;
        $("#btn__login").text(this.props.langData.btn_label);
      } else if (!this.state.password) {
        $(".error_password").css("display", "block").text("Password required");
        document.getElementById("btn__login").disabled = false;
        $("#btn__login").text(this.props.langData.btn_label);
      } else {
        (async () => {
          const { data, message, status_code, success } = await handleLogin(
            this.state
          );

          if (success == true && status_code == 200100) {
            // redirect member home pages
            saveState("setupTime", this.dateNow);

            if (this.props.props.location.search != "") {
              this.requestUrl = this.props.props.location.search.substring(
                this.props.props.location.search.indexOf("?") + 1
              );
              this.arrayParamsUrl = JSON.parse(
                '{"' +
                  this.requestUrl.replace(/&/g, '","').replace(/=/g, '":"') +
                  '"}',
                function (key, value) {
                  return key === "" ? value : decodeURIComponent(value);
                }
              );
              if (
                this.arrayParamsUrl.is_redeem == 1 &&
                this.arrayParamsUrl.promotion_id !== ""
              ) {
                if (await getRewardsProfile()) {
                  navigate(
                    this.props.redemptionUrl +
                      "/" +
                      this.arrayParamsUrl.promotion_id
                  );
                } else {
                  navigate(this.props.urlRedirect);
                }
              } else {
                navigate(this.props.urlRedirect);
              }
            } else {
              navigate(this.props.urlRedirect);
            }
          } else if (success == true && status_code == 200108) {
            // redirect to verify account page if status = 0
            saveState("setupTime", this.dateNow);
            navigate(this.props.verifyAccountUrl + "?email=" + data.email);
          } else if (success == true && status_code == 401) {
            // unauthorize
            alert(message);
            navigate(this.loginUrl);
          } else if (success == true || success == false) {
            // another error
            $(".error_password").css("display", "block").text(message);
            document.getElementById("btn__login").disabled = false;
            $("#btn__login").text(this.props.langData.btn_label);
          } else {
            // no connection
            $(".error_password")
              .css("display", "block")
              .text(this.props.langData.login_error_no_connection);
            document.getElementById("btn__login").disabled = false;
            $("#btn__login").text(this.props.langData.btn_label);
          }
        })();
      }
    } catch (e) {
      console.log(e);
    }
  }

  showErrorEmail(message = null) {
    var error_email = $(".error_email");
    var link_regiser = $(".link_regiser");

    error_email.css("display", "block");
    link_regiser.css("display", "block");
    error_email.text(
      message !== null
        ? message
        : "Email address not registered. Check again or create new account"
    );
    return this.showPaswordField(false);
  }

  showPaswordField(status) {
    $(".subtitle").text(this.props.langData.password_info);
    return this.setState({
      show_password_field: status,
    });
  }

  render() {
    return (
      <FormLogin
        props={this.props}
        show_password_field={this.state.show_password_field}
        handleUpdate={(e) => this.handleUpdate(e)}
        handleSubmitEmail={(e) => this.handleSubmitEmail(e)}
        handleSubmit={(e) => this.handleSubmit(e)}
      />
    );
  }
}

export default isAuth0Enabled
  ? withAuthenticationRequired(Login, {
      loginOptions: {
        authorizationParams: {
          ui_locales: languageSession === "zh" ? "zh-CN" : languageSession,
        },
      },
    })
  : Login;
