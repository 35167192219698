module.exports = {
  'title_login': '登录',
  'subtitle': '输入您在注册阿雅娜会员优享计划时使用的邮件',
  'placeholder_email': '邮件',
  'placeholder_password': '输入密码',
  'remember_label': '记住登录状态',
  'forgot_password_label': '忘记密码？',
  'btn_label': '登录',
  'title_another_login': '或使用其它登录方式',
  'login_error_email_text': '无法找到您的邮件地址',
  'login_error_email_text_empty': 'Email can not be null',
  'login_error_password_text': 'Wrong password',
  'login_error_password_text_empty': 'Password can not be null',
  'sugest_register_link': '您想 ',
  'register_link': '创建一个新的账户',
  'sugest_register_link_2': ' 吗？',
  'btn_next_label': '下一步',
  'title_register': '还不是阿雅娜会员优享计划成员？ ',
  'join_label': '立即注册',
  'password_info': '请输入密码',
  'login_error_no_connection': '无法连接。请检查您的网络并尝试重新连接。'
}